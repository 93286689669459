import React, { useRef } from "react"
import SeoMeta from "~components/molecules/seo-meta"
import { useTranslation } from "react-i18next"
import { GridContainer } from "~styles/grid"
import { StaticImage } from "gatsby-plugin-image"
import NavigationMenu from "~components/organisms/navigation-menu"
import { PageProps } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"
import useHypothesis from "src/hooks/useHypothesis"
import { useEffect } from "react"
import * as Styled from "../styles/page-styles/index"
import useIsMobile from "src/hooks/useIsMobile"
import useNavMenuContext from "src/hooks/useNavMenuContext"
import NavMenuProvider from "~components/organisms/navigation-menu/nav-menu-context"
import useThemeSwitcherContext from "src/hooks/useThemeSwitcherContext"
import { THEME_MODES } from "src/context/theme-switcher-context"
import { BackgroundGlobals } from "~styles/globals"
import { useTheme } from "styled-components"

import ArrowDownSVG from "../images/icons/arrow_down.svg"
import HamburgerSVG from "../images/icons/hamburger.svg"

import ServiceWorkerDialog from "~components/sw-dialog"

const IndexPage: React.FC<PageProps> = ({ location }) => {
  const { hypothesis, hideHypothesis } = useHypothesis()
  const { t } = useTranslation(["common", "home"])
  const { locale } = useLocalization()
  const ref = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()
  const { themeMode } = useThemeSwitcherContext()

  const isMobile = useIsMobile(() => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.documentElement.style.setProperty(
      "--scrollbarWidth",
      `${scrollbarWidth}px`
    )
  })

  const scrollToToC = () => {
    if (!ref || !ref.current) return

    scrollTo({ top: ref.current.offsetTop, behavior: "smooth" })
  }

  useEffect(() => {
    hideHypothesis()
  }, [hypothesis])

  return (
    <NavMenuProvider>
      <SeoMeta title={t("home:title")} lang={locale} url={location.pathname} />
      <BackgroundGlobals color={theme.palette.light} />
      <NavigationMenu
        independentHiding
        ignoreHypothesis
        reduced={isMobile}
        currentPath={location.pathname}
        renderProps={{ disableProgressText: true }}
      />
      <GridContainer style={{ minHeight: "100vh" }} $noConstraint as="main">
        <Styled.ImageWrapper
          $inverted={themeMode === THEME_MODES.DARK}
          as="aside"
          aria-label="Publication cover image"
        >
          <StaticImage
            style={{ height: "100%", width: "100%" }}
            src="../images/cover_1.svg"
            alt="Empty text bubbles in rectangles"
            placeholder="blurred"
          />
        </Styled.ImageWrapper>
        <Styled.ContentWrapper
          as="section"
          role="region"
          aria-label="Publication details"
        >
          {!isMobile && (
            <Styled.Header aria-hidden="true">
              <NavMenuToggle />
              <Styled.LanguagePicker
                flex
                dark
                standalone
                currentPath={location.pathname}
              />
            </Styled.Header>
          )}
          <Styled.Center role="heading" aria-level={1}>
            <Styled.Title
              $contrasty={themeMode === THEME_MODES.DARK}
              role="img"
              aria-label="Odszkolnić! Deschool! | 08.07 - 18.08.2022"
            />
          </Styled.Center>
          <Styled.TocButton role="button" tabIndex={0} onClick={scrollToToC}>
            <Styled.TocBtnContent>
              <Styled.TocBtnText>{t("home:toc")}</Styled.TocBtnText>
              <Styled.ArrowDownImg
                src={ArrowDownSVG}
                alt="Arrow down"
                themeMode={themeMode}
              />
            </Styled.TocBtnContent>
          </Styled.TocButton>
        </Styled.ContentWrapper>
        <Styled.TocWrapper ref={ref}>
          <Styled.TableOfContents headless />
        </Styled.TocWrapper>
      </GridContainer>
      <ServiceWorkerDialog />
    </NavMenuProvider>
  )
}

export default IndexPage

const NavMenuToggle: React.FC = () => {
  const { toggleNav } = useNavMenuContext()
  const { themeMode } = useThemeSwitcherContext()

  return (
    <Styled.NavBtn
      onClick={() => setTimeout(() => toggleNav(), 180)}
      style={{ filter: themeMode === THEME_MODES.DARK ? "invert(1)" : "none" }}
    >
      <img
        className="sizeable-icon"
        src={HamburgerSVG}
        alt="Toggle Menu Button"
      />
    </Styled.NavBtn>
  )
}
